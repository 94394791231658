import React from "react"

import Layout from "components/Layout/en"
import Image from "components/Image/index"
import Product from "components/Layout/Product/index"
import productImg from "./wood.mp4"
import productIcon from "./WoodScanner.svg"
import wood1 from "./wood1.png"
import { Table } from "react-bootstrap"
import { MdDone, MdCancel } from "react-icons/md"

import image1 from "./wood_inspection_0.jpg"
import image2 from "./product.jpg"

export default function(props) {
  const description = (
    <React.Fragment>
      <p>
        Determine precisely and automate the quality of logs before you process
        them. The MoonVision software uses visual data from cameras to check -
        whether their quality characteristics are met and which areas can be
        used for which applications.
      </p>
      <h3>Highlights</h3>
      <ul>
        <li>Automated quality inspection of processed and unprocessed wood</li>
        <li>Benefit from consistent quality and reduced lead times</li>
        <li>Detection and measurement with a camera & software</li>
        <li>Highest accuracy & comfort through artificial intelligence</li>
        <li>Visual Output or forwarding to subsequent systems</li>
      </ul>
    </React.Fragment>
  )

  return (
    <Layout location={props.location}>
      <Product
        id="WoodScanner"
        title="Quality inspection of wood in real time"
        subtitle="Efficient Software for wood analysis - simple and efficient in use"
        description={description}
        productImg={productImg}
        productIcon={productIcon}
        productFeatures={[
          "Automated wood testing",
          "Consistently high quality",
          "Shortened lead time",
          "Find out now",
        ]}
        images={[image1, image2]}
        type="wood"
        technicalTable={
          <React.Fragment>
            <Table responsive size="sm">
              <thead>
                <tr>
                  <th colSpan="2">Quality class recognition</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Reduced resource requirements</td>
                  <td>
                    <MdDone />
                  </td>
                </tr>
                <tr>
                  <td>Reduction of cycle time</td>
                  <td>
                    <MdDone />
                  </td>
                </tr>
              </tbody>
              <thead>
                <tr>
                  <th colSpan="2">Quality class recognition</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>A</td>
                  <td>
                    <MdDone />
                  </td>
                </tr>
                <tr>
                  <td>B</td>
                  <td>
                    <MdDone />
                  </td>
                </tr>
                <tr>
                  <td>C</td>
                  <td>
                    <MdDone />
                  </td>
                </tr>
                <tr>
                  <td>D (CX)</td>
                  <td>
                    <MdDone />
                  </td>
                </tr>
              </tbody>
              <thead>
                <tr>
                  <th colSpan="2">Detection of dimensions</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Length</td>
                  <td>
                    <MdDone />
                  </td>
                </tr>
                <tr>
                  <td>Width</td>
                  <td>
                    <MdDone />
                  </td>
                </tr>
                <tr>
                  <td>Depth</td>
                  <td>
                    <MdCancel />
                  </td>
                </tr>
              </tbody>
              <thead>
                <tr>
                  <th colSpan="2">Differentiation between characteristics</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Fix/ rotten Knots</td>
                  <td>
                    <MdDone />
                  </td>
                </tr>
                <tr>
                  <td>Cracks</td>
                  <td>
                    <MdDone />
                  </td>
                </tr>
                <tr>
                  <td>Length</td>
                  <td>
                    <MdDone />
                  </td>
                </tr>
                <tr>
                  <td>Width</td>
                  <td>
                    <MdDone />
                  </td>
                </tr>
                <tr>
                  <td>Depth</td>
                  <td>
                    <MdCancel />
                  </td>
                </tr>
                <tr>
                  <td>Deforestation</td>
                  <td>
                    <MdDone />
                  </td>
                </tr>
                <tr>
                  <td>Torsiom</td>
                  <td>
                    <MdDone />
                  </td>
                </tr>
                <tr>
                  <td>Crookedness</td>
                  <td>
                    <MdDone />
                  </td>
                </tr>
              </tbody>
            </Table>
            <Image filename={wood1} alt="wood" />
          </React.Fragment>
        }
      >
        <React.Fragment>
          <p>
            The MoonVision WoodScanner was specially developed for surface
            inspection of wood. New technologies such as machine learning are
            used to detect even the smallest deviations, which are difficult for
            employees to detect in a short period of time, and to classify the
            wood on this basis. Subsequently, measurements can also be carried
            out. Tests can ensure that the wood meets applicable standards, but
            you can also define your own testing rules. The newly generated
            information can be used to subdivide the wood e.g. into different
            application or to generate statistics.
          </p>
          <p>
            The artificial intelligence used outshines existing approaches with
            regard to inspection accuracy - the evaluation process is fully
            automated. You profit from the time savings.
          </p>
          <p>
            The MoonVision software can be used for raw wood as well as wood
            products of any kind. We can help you to:
          </p>
          <ul>
            <li>Automated the recognition of different quality classes</li>
            <li>Categorize surface parts</li>
            <li>Detect defects and measure their characteristics precisely</li>
          </ul>
          <p>
            The MoonVision WoodScanner can be used at different production steps
            from the incoming goods inspection to the outgoing goods inspection.
            Due to its self-sufficient design, the software can be integrated
            into a running production process or existing ERP systems.
          </p>
          <p>At the end of the process, results can also be:</p>
          <ul>
            <li>
              Displayed visually(on a monitor/alarms/storage of jpg files)
            </li>
            <li>Transmitted in tabular form</li>
            <li>Passed on to management information systems via APIs</li>
            <li>Used for process control via APIs</li>
          </ul>
          <p>Thanks to the MoonVision concept</p>
          <ul>
            <li>Individual adaptations can be carried out independently</li>
            <li>You stay in control of access and checkable parameters</li>
          </ul>
          <h3>Available where you need it</h3>
          <p>
            The MoonVision WoodScanner can use all existing camera systems as
            data sources - which meet the Genicam standard. We are also happy to
            advise you on the choice of the right system. The verification can
            be done in real time via our online platform. Alternatively, we can
            also install the system on site. In both cases you get access to the
            MoonVision Toolbox and can make adjustments e.g. for parameter
            adaptation and personalization at any time.
          </p>
          <h3>Simple to use</h3>
          <p>
            Due to the software design, the optimization and implementation of
            the MoonVision Woodscanner can be realized on site with little
            effort. After the examination of a low amount of pictures/videos you
            will receive certainty about the quality. Further pictures data can
            be addedat a later time to improve accuracy. All data is collected
            and stored centrally and can be retrieved by you at any time.
          </p>
        </React.Fragment>
      </Product>
    </Layout>
  )
}
